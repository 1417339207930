$breakpoints: (
  mobile_small: 320px,
  mobile_medium: 375px,
  mobile_big: 425px,
  tablet: 640px,
  tablet_custom: 768px,
  desktop: 991px,
  wide: 1200px,
  // Tweakpoints
  desktopAd: 810px,
  mobileLandscape: 480px,
);

$show-breakpoints: (mobile_small, mobile_medium, mobile_big, mobileLandscape, tablet, tablet_custom, desktop, wide);

$colors: (
  text: #314676,
  white: #FFFFFF,
  buttons: (
    default: #3B97C7,
    default_hover: #317EA7
  )
);

$fontsize: (
  extra_small: 12px,
  small: 14px,
  default: 16px,
  normal: 18px,
  medium: 20px,
  title: 24px,
  h1: 42px
);

$fonts: (
    'Rubik-MediumItalic',
    'Rubik-Italic',
    'Rubik-Bold',
    'Rubik-Regular',
    'Rubik-Medium',
    'Rubik-BoldItalic',
    'Rubik-Black',
    'Rubik-Light',
    'Rubik-LightItalic',
    'Rubik-BlackItalic',
    'PressStart2P-Regular'
);