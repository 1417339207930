@use 'settings.scss' as *;

@use '_mq.scss' as mq with (
  $breakpoints: $breakpoints,
  //$show-breakpoints: $show-breakpoints
);

@import 'mixins';

@import '~normalize.css/normalize.css';

/* Generate fonts  */

@include gen-fonts($fonts);

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Rubik-Regular';
    font-style: normal;
    height: 100%;
    overflow-x: hidden;
}

body {
    background-color: map-get($colors, background);
    overflow-x: hidden;
}

.d-contents {
    display: contents !important;
}

.fullwidth {
    width: 100% !important;
}

a {
    line-height: 0;
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.clear-link {
        &:hover {
            text-decoration: none;
        }
    }

    &.link-underlined {
        text-decoration: underline;
    }
}

.pc-only {
    @include mq.mq($until: 1024px){
        display: none !important;
    }
}

.small-devices {
    @include mq.mq($from: 1025px){
        display: none !important;
    }

    @include mq.mq($until: 1024px){
        display: initial !important;
    }
}

.btn {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &_default {
        color: map-get($colors, white);
        text-transform: capitalize;
        font-size: map-get($fontsize, default);
        line-height: 19px;
        background: map-get($colors, buttons, default);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 12px;
        width: 107px;
        height: 44px;
        transition: 0.3s;

        &:hover {
            background: map-get($colors, buttons, default_hover);
        }
    }

    &_pixel {
        width: 377px;
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: map-get($fontsize, default);
        line-height: 19px;
        color: map-get($colors, white);
        background: url('../images/btn_pixel.svg');
        background-repeat: no-repeat;
        background-position: center center;

        &:last-of-type{
            margin-top: 26px;
            @include mq.mq($until: 450px){
                margin-top: 10px;
            }

            @include mq.mq($until: 375px){
                margin-top: 2px;
            }
        }

        &:hover {
            position: relative;
            filter: drop-shadow(0px 4px 20px #136C82);
        }

        @include mq.mq($until: 450px){
            background-size: contain;
            padding: 30px;
        }
    }
}

.adaptive {
    @include adaptive-width;
}

.management {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-person {
    width: 16px;
    height: 16px;
    margin-right: 14px;
}

.ul-clear {
    list-style: none;
    padding: 0;;
}

.dropdown {

    position: relative;

    &__arrow {
        margin-left: 16px;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        position: absolute;
        top: 25px;
        color: map-get($colors, text);

        background: map-get($colors, white);
        border: 1px solid #3B97C7;
        border-radius: 2px;
        padding: 20px;

        display: none;

        li {

            &:not(:first-child){
                margin-top: 15px;
            }

            a {
                transition: 0.3s;
            }

            a:hover {
                color: #00B6E3;
            }
            
        }
    }
}

.header {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 28px 14px;
    background-color: map-get($colors, white);

    @include mq.mq($until: 1024px){
        justify-content: space-between;
        padding: 30px 80px;
    }

    @include mq.mq($until: 475px){
        padding: 30px 24px;
    }

    &__nav {
        ul {
            padding: 0;
            list-style: none;
            margin: 0 35.5px 0 44.5px;

            li {
                text-transform: uppercase;
                display: inline-block;
                margin: 8px 16.5px;
                color: map-get($colors, text);

                a {
                    transition: 0.3s;

                    &:hover {
                        text-decoration-line: underline;
                        color: #0B1318;
                    }
                }
            }
        }
    }

    .dropdown {
        margin-right: 31px;
        text-transform: uppercase;
        font-family: 'PressStart2P-Regular';
        font-style: normal;
        line-height: 16px;
        
        &__item {
            color: map-get($colors, text);
        }
    }
    
    .mob-menu {
        position: absolute;
        left: 0;
        top: 112px;
        z-index: 10;
        width: 100%;
        background: rgba(59, 151, 199, 0.95);
        padding: 40px 24px 60px;
        color: map-get($colors, white);
        display: none;

        .nav {
            li {
                margin-top: 20px;
                text-transform: uppercase;
                line-height: 19px;
            }
        }

        .language {

            ul {
                margin: 20px -12.5px 0;

                li {
                    font-family: 'PressStart2P-Regular';
                    display: inline-block;
                    margin: 20px 12.5px;
                }
                
                .active, a:hover {
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: #60DAF8;
                }
            }
        }

        .btn_default {
            background: #78CBEE;
            margin-top: 20px;
            color: #205571;
        }

    }
}

.burger {
    width: 24px;
    cursor: pointer;
}

.container {
    
    .welcome {
        min-height: 745px;
        background: url('../images/bg_main.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        padding: 165px 0;

        @include mq.mq($until: 1024px){
            padding: 125px 0;
        }

        @include mq.mq($until: 450px){
            padding: 30px 0;
            background-position-x: 58%;
        }

        &__content {
            @extend %block;

            @include mq.mq($until: 900px){
                padding: 0 20px !important;
            }
        }

        &__title {
            color: #0F3260;
            font-family: 'PressStart2P-Regular';
            font-size: map-get($fontsize, h1);
            line-height: 160%;
            letter-spacing: 0.01em;
            width: 563px;
            max-width: 100%;
            margin: 0;
            margin-bottom: 14px;

            @include mq.mq($until: 450px){
                font-size: map-get($fontsize, title);
                text-align: center;
                margin-bottom: 24px;
            }
        }

        .btn_pixel {
            
            @include mq.mq($until: 450px){
                margin-left: 0;
                max-width: 100%;
            }
        }
    }
}


.title {
    width: 724px;
    max-width: 100%;
    text-align: center;
    font-family: 'PressStart2P-Regular';
    font-size: map-get($fontsize, title);
    line-height: 190%;
    margin: 120px auto 30px;
    color: map-get($colors, text);

    &-small {
        font-family: 'Rubik-Medium';
        font-size: map-get($fontsize, title);
        line-height: 150%;
        color: map-get($colors, text);
        max-width: 100%;
        margin-bottom: 30px;

        @include mq.mq($until: 1024px){
            margin-top: 50px;
        }

        @include mq.mq($until: 475px){
            margin-top: 40px;
            font-size: map-get($fontsize, default);
        }
    }

    @include mq.mq($until: 425px){
        font-size: map-get($fontsize, normal);
        margin: 80px auto 10px;
    }

    .badge {
        background: #3B97C7;
        border-radius: 4px;
        font-size: map-get($fontsize, extra_small);
        line-height: 14px;
        font-family: 'Rubik-Medium';
        color: map-get($colors, white);
        padding: 3px 13px;
        position: relative; 
        bottom: 20px;
        right: 31px;
    }
}

%block {
    width: 1072px;
    max-width: 100%;
    margin: 0 auto;
    @include mq.mq($until: 1100px){
        padding: 0 10px;
        width: 885px;
    }

    @include mq.mq($until: 425px){
        padding: 0 24px;
    }
}

.cards {

    @extend %block;

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 -10px;

       /*  @include mq.mq($until: 1100px){
            margin: 0 auto;
        } */
    }

    .card {
        margin: 10px;
        width: 344px;
        min-height: 540px;
        max-width: 100%;
        background: url('../images/bg_card.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 12px;
        padding: 60px 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @include mq.mq($until: 1100px){
            width: 275px;
        }

        @include mq.mq($until: 425px){
            width: 100%;
            padding: 30px 46px;
            min-height: auto;
        }

        &__head {
            display: flex;
            justify-content: center;
            align-items: center;

            &_column {
                flex-direction: column;

                .card__title {
                    text-align: center;
                    padding-top: 10px;
                    line-height: 160%;
                }
            }
        }

        &__icon {
            background: #E3F2FA;
            width: 55px;
            height: 55px;
            border-radius: 50%;
        }

        &__image {
            position: relative;
            top: -10px;
            left: -1px;
        }

        &__title {
            color: map-get($colors, white);
            text-transform: uppercase;
            font-family: 'PressStart2P-Regular';
            font-size: map-get($fontsize, normal);
            line-height: 18px;
            padding-left: 15px;

            @include mq.mq($until: 425px){
                font-size: map-get($fontsize, default);
                line-height: 160%;
            }
        }

        .price, .detail {
            font-family: 'Rubik-Medium';
            font-size: map-get($fontsize, title);
            line-height: 28px;
            text-transform: lowercase;
            color: map-get($colors, text);
            padding-top: 15px;
            padding-bottom: 20px;
        }

        .border {
            width: 100%;
            height: 1px;
            background-color: #3B97C7;
            margin-bottom: 15px;
        }

        &__label {
            color: map-get($colors, text);
            text-align: left;
            margin-top: 15px;
            margin-right: auto;
            font-family: 'Rubik-Light';

            span {
                font-family: 'Rubik-Medium';
            }
        }

        .btn_default {
            width: 100%;
            margin-top: 25px;
            margin-bottom: 20px;
        }

        .btn_transparent {
            color: map-get($colors, text);
            font-family: 'Rubik-Light';
        }

    }

    &_detailed {
        margin-bottom: 90px;

        .card {
            padding: 40px 43px 20px;

            .detail-label {
                font-family: 'Rubik-Light';
                line-height: 19px;
                color: map-get($colors, text);

                &:first-of-type {
                    margin-top: 10px;
                }
            }

            .price, .detail {
                padding-bottom: 15px;
            }
        }

        br {
            display: block;
            content: "";
            margin: 5px 0;
        }
    }
    
}

.about  {

    @extend %block;

    &__item {
        display: flex;
        align-items: center;
        padding: 15px 0;

        @include mq.mq($until: 500px){
            flex-direction: column;
            align-items: flex-start;
            /* margin-left: 14px;
            margin-right: 14px; */
            padding: 25px 0 15px;
        }

        &:not(:last-child){
            border-bottom: 1px solid #8BD0F1;
        }
    }

    &__image {
        width: 190px;
        height: 190px;

        @include mq.mq($until: 576px){
            width: 110px;
            height: 110px;
        }
    }

    &__descr {
        margin-left: 63px;
        flex-grow: 1;

        @include mq.mq($until: 500px){
            margin-left: 0;
            margin-top: 15px;
        }
    }

    &__title {
        font-size: map-get($fontsize, normal);
        line-height: 150%;
        color: map-get($colors, text);
        font-family: 'PressStart2P-Regular';

        @include mq.mq($until: 576px){
            font-size: map-get($fontsize, default);
        }
    }

    &__text {
        margin-top: 15px;
        line-height: 160%;
        color: #151313;
        max-width: 95%;
    }
}


.contacts {

    position: relative;

    @include mq.mq($until: 450px){
        margin-bottom: 116px;
    }

    .blur {
        background: rgba(0, 182, 227, 0.2);
        filter: blur(125px);
        width: 335px;
        height: 335px;
        border-radius: 50%;
        position: absolute;

        &_1 {
            background: rgba(0, 182, 227, 0.5);
            top: 0;
            left: 0;

            @include mq.mq($until: 1024px){
                left: -60px;
            }

            @include mq.mq($until: 450px){
                display: none;
            }
        }

        &_2 {
            bottom: -220px;
            left: 100px;

            @include mq.mq($until: 1024px){
                left: 0;
            }
        }

        &_3 {
            bottom: -86px;
            right: -6px;

            @include mq.mq($until: 1100px){
                right: 0;
            }

            @include mq.mq($until: 670px){
                display: none;
            }
        }
    }

    .cloud {
        position: absolute;
        z-index: 3;

        &_1 {
            top: -83px;
            left: 7px;

            @include mq.mq($until: 1024px){
                left: -80px;
                top: -80px;
            }

            @include mq.mq($until: 450px){
                display: none;
            }
        }

        &_2 {
            bottom: -136px;
            left: 100px;

            @include mq.mq($until: 1024px){
                left: 0;
            }

            @include mq.mq($until: 450px){
                bottom: -117px;
                left: -20px;
            }
        }

        &_3 {
            bottom: -80px;
            right: -6px;

            @include mq.mq($until: 1100px){
                right: -20px;
            }

            @include mq.mq($until: 1024px){
                right: -107px;
            }

            @include mq.mq($until: 670px){
                display: none;
            }
        }
    }

    margin-bottom: 162px;

    &__cards {
        @extend %block;

        position: relative;
        
        &-items {
            margin: 0 -10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include mq.mq($until: 1100px){
               /*  margin: 0 auto; */
                justify-content: center;
            }

/*             @include mq.mq($until: 450px){
                padding: 0 14px;
            } */
        }
    
        .contact-card {
            width: 253px;
            max-width: 100%;
            padding: 50px 24px;
            background: #2BBCEE;
            background: url('../images/contact_card.png');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            min-height: 293px;
            border-radius: 12px;
            margin: 0 10px;
            position: relative;
            z-index: 1s;

            @include mq.mq($until: 1100px){
                width: 201px;
                padding: 50px 14px;
            }

            @include mq.mq($until: 1024px){
                margin: 0 10px 15px;
            }

            @include mq.mq($until: 450px){
                width: 100%;
                text-align: center;
                min-height: auto;
                padding: 30px 24px;
            }

            &:nth-child(1){
                min-height: 328px;
                position: relative;
                top: 18px;

                @include mq.mq($until: 450px){
                    top: auto;
                    min-height: auto;
                }
            }
/* 
            &:nth-child(2){
                min-height: 429px;
            }
 */
            &:nth-child(3){
                min-height: 293px;

                @include mq.mq($until: 450px){
                    min-height: auto;
                }
            }

            &:nth-child(4){
                min-height: 296px;
                margin-bottom: auto;

                @include mq.mq($until: 450px){
                    min-height: auto;
                }
            }

            &__icons {
                display: flex;
                align-items: center;
                margin: 0 -5px;

                @include mq.mq($until: 450px){
                    justify-content: center;
                }
            }

            &__title {
                color: map-get($colors, text);
                font-family: 'PressStart2P-Regular';
                line-height: 110%;
                font-size: map-get($fontsize, small);
                margin-bottom: 10px;
                margin-top: 20px;

                br {
                    display: block;
                    content: "";
                    margin: 10px 0;
                }
            }

            &__label {
                font-size: map-get($fontsize, normal);
                line-height: 21px;
                color: #151313;

                @include mq.mq($until: 1100px){
                    font-size: map-get($fontsize, small);
                    line-height: 17px;
                }
            }

            &__subtitle {
                color: map-get($colors, text);
                font-family: 'Rubik-Medium';
                margin-bottom: 10px;
                margin-top: 15px;
            }

            .btn_default {
                width: 100%;
                margin-top: 25px;
                text-transform: none;

                @include mq.mq($until: 450px){
                    width: 173px;
                    margin: 25px auto 0;
                    max-width: 100%;
                }
            }
        }
    }

    &__icon {
        margin: 0 5px;
    }

    &_page {

        .title {
            margin-top: 0;
        }

        @include mq.mq($until: 1024px){
            margin-bottom: 46px;

            .cloud {

                &_2 {
                    bottom: -48px;
                }
    
                &_3 {
                    bottom: -46px;
                }
            }
        }

        @include mq.mq($until: 650px){

            .cloud {
                &_2 {
                    bottom: -68px;
                    left: -7px;
                }
            }
            margin-bottom: 86px;
        }

        @include mq.mq($until: 450px){
            .contact-card {

                &:last-child {
                    margin-bottom: 50px;
                }
            }

            .blur {
                display: none;
            }
        }
        
    }
}

.logo-footer {
    width: 190px;
    height: 40px;
    margin-right: 23px;

    @include mq.mq($until: 830px){
        margin-right: 0;
    }
}

.partners {
    background: rgba(233, 233, 233, 0.24);
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .mob-only {
        @include mq.mq($from: 401px){
            display: none;
        }
    }

    .mob-margin {
        @include mq.mq($until: 400px){
            margin-right: 0;
        }
    }

    ul {
        padding: 0;
        list-style: none;

        @include mq.mq($until: 991px){
            padding: 0 24px;
            text-align: center;
        }

        @include mq.mq($until: 576px){
            padding: 20px 24px;
        }

        li {
            display: inline-block;
            font-family: 'PressStart2P-Regular';
            text-transform: uppercase;
            font-size: map-get($fontsize, normal);
            line-height: 18px;
            color: rgba(49, 70, 118, 0.3);
            margin: 10px 0;

            @include mq.mq($until: 400px){
                margin: 10px 20px;
                line-height: 28px;
            }

            &:not(:last-child)::after {
                content: '|';
                padding: 0 40px;
                
                @include mq.mq($until: 1100px){
                    padding: 0 20px;
                }

                @include mq.mq($until: 991px){
                    padding: 0 10px;
                }

                @include mq.mq($until: 400px){
                    display: none;
                }
            }
        }
    }
}

.footer {
    min-height: 261px;
    width: 100%;
    background: url('../images/bg_footer.svg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &__content {
        @extend %block;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 50px 0;

        @include mq.mq($until: 910px){
            padding: 50px 10px;
        }

        @include mq.mq($until: 830px){
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @include mq.mq($until: 475px){
            padding: 50px 24px;
        }

        @include mq.mq($until: 335px){
            padding: 50px 18px;
        }
    }

    &__nav {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        /* flex-basis: 650px; */
        flex-basis: 590px;

        @include mq.mq($until: 830px){
            flex-basis: auto;
            justify-content: space-between;
            margin-top: 42px;
            width: 550px;
            max-width: 100%;
        }

        &-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @include mq.mq($until: 475px){
                max-width: 100%;
                width: 153px;
            }

            @include mq.mq($until: 360px){
                width: 143px;
            }

            @include mq.mq($until: 335px){
                width: 140px;
            }

            &:first-child {
                margin: 0 60px;

                @include mq.mq($until: 830px){
                    margin: 0;
                }
            }

            &:nth-child(2){
                margin-right: 60px;
                @include mq.mq($until: 830px){
                    margin-right: 0;
                }
            }

            &:nth-child(3){
                @include mq.mq($until: 475px){
                    margin-top: 20px;
                }
            }

            &:nth-child(4){
                margin-left: auto;

                @include mq.mq($until: 1100px){
                    margin-left: 60px;
                    margin-top: 20px;
                }

                @include mq.mq($until: 830px){
                    margin-left: 0;
                }
            }
        }

        &-title {
            font-size: map-get($fontsize, extra_small);
            line-height: 150%;
            color: map-get($colors, text);
            font-family: 'PressStart2P-Regular';
            min-width: 116px;

            @include mq.mq($until: 475px){
                border-bottom: 1px solid map-get($colors, text);
                padding-bottom: 5px;
                margin-bottom: 5px;
            }
        }

        &-icon {
            width: 24px;
            margin-bottom: 8px;
        }

        .border {
            width: 100%;
            background: map-get($colors, text);
            height: 1px;
            margin-bottom: 10px;

            @include mq.mq($until: 475px){
                display: none;
            }
        }

        &-items {
            padding: 0;
            list-style: none;

            li {
                font-size: map-get($fontsize, small);
                line-height: 17px;
                color: #151313;
                margin-top: 10px;
            }
        }
    }

    .payments {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -5px;
        width: 284px;
        max-width: 100%;

        @include mq.mq($until: 475px){
            margin: 10px -5px 0;
        }

        img {
            border-radius: 3.27273px;
            margin: 5px;

            @include mq.mq($until: 475px){
                margin: 2.5px;
            }
        }
    }

    .payment-icon-small {
        width: 36px;

        @include mq.mq($until: 475px){
            width: 28px;
            height: 28px;
        }
    }

    .payment-icon-big {
        width: 80px;

        @include mq.mq($until: 475px){
            width: 61px;
            height: 28px;
        }
    }
}

.breadcrumbs {
    @extend %block;
    margin: 20px auto 40px;

    @include mq.mq($until: 475px){
        margin: 5px auto 31px;

    }

    li {
        display: inline-block;
        color: rgba(21, 19, 19, 0.5);

        &:not(:first-child)::before{
            content: '\\';
            margin: 0 10px;
        }

        &.active, a:hover {
            color: #151313;
        }

        @include mq.mq($until: 475px){
            &:first-child {
                display: none;
            }

            &:not(:first-child)::before{
                content: '<';
                margin: 0 13px 0 0;
            }
        }
    }
}

.description {
    @extend %block;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
        font-size: map-get($fontsize, title);
        line-height: 150%;
        color: map-get($colors, text);
        font-family: 'PressStart2P-Regular';
        width: 432px;
        max-width: 100%;

        @include mq.mq($until: 475px){
            font-size: map-get($fontsize, normal);
        }
    }
    
    &_content {
        margin-bottom: 100px;
        
        h1 {
            width: 600px;
        }

        p:not(.description__title){
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    &__title {
        margin-bottom: 5px;
        margin-top: 20px;
        font-family: 'Rubik-Medium';
    }

    &__text {
        margin-top: 4px;
        line-height: 140%;
        color: #151313;
        padding: 10px 19px;
        border-left: 4px solid map-get($colors, text);
        border-radius: 2px;
        word-break: break-word;
    }

    &__image {
        width: 426px;
        max-width: 100%;
        position: relative;
        z-index: 3;

        @include mq.mq($until: 1024px){
            width: 321px;
        }

        @include mq.mq($until: 475px){
            margin: 0 auto;
            display: block;
        }
    }

    &__left {
        flex-grow: 1;
        flex-basis: 400px;
        max-width: 100%;
    }

    &__right {
        position: relative;
        @include mq.mq($until: 475px){
            flex-grow: 1;
            margin-top: 30px;
        }
    }

    .blur {
        background: rgba(0, 182, 227, 0.2);
        filter: blur(125px);
        position: absolute;
        z-index: 2;
        width: 281px;
        height: 281px;

        @include mq.mq($until: 475px){
            display: none;
        }

        &_1 {
            top: 2px;
            right: -20px;
        }

        &_2 {
            top: 2px;
        }
    }
}

.wrapper {
    @extend %block;
}

.advanced {

    margin-bottom: 92.5px;

    @include mq.mq($until: 475px) {
        margin-bottom: 72.5px;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -10px;

        @include mq.mq($until: 900px) {
            justify-content: center;
        }
    }

    &__item {
        width: 344px;
        min-height: 185px;
        background: url('../images/bg_advanced.svg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 12px;
        padding: 24px 24px 15px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 7.5px 10px;

        @include mq.mq($until: 1100px) {
            width: 275px;
        }

        @include mq.mq($until: 475px) {
            width: 100%;
        }

        .price {
            font-size: map-get($fontsize, title);
            line-height: 28px;
            color: map-get($colors, text);
            margin-top: 10px;
            font-family: 'Rubik-Medium';
        }

        .descr {
            margin-top: 10px;
            text-align: center;
            line-height: 140%;
            color: #151313;
            width: 230px;
            max-width: 100%;
        }

        .btn_transparent {
            text-decoration: underline;
            margin-top: 15px;
        }
    }
}

.table {

    @include mq.mq($until: 850px){
        overflow-x: auto;
        margin-right: -10px;
    }

    @include mq.mq($until: 425px){
        margin-right: -24px;
    }

    text-align: center;
    border: none;
    margin-bottom: 80px;

    table {
        border-collapse: collapse;
        width: 100%;
    }

    tr {

        th {
            color: map-get($colors, white);
            font-weight: normal;
            font-family: 'PressStart2P-Regular';

            &.colored {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                background: #E3F3FA;
                padding-bottom: 20px;
                font-size: map-get($fontsize, small);

                @include mq.mq($until: 1100px){
                    font-size: map-get($fontsize, extra_small);
                }

                span {
                    background: #78CBEE;
                    border-radius: 4px;
                    padding: 16px 5px;
                    display: block;
                    width: 100%;
                }
            }
            
        }

        &:not(:first-of-type){
            td:not(.colored) {
                border-top: 5px solid map-get($colors, white);
            }

            td {
                border-top: 5px solid rgb(233 244 250);
            }
        }

        td {
            background: rgba(222, 229, 232, 0.1);
            color: #151313;
            font-size: map-get($fontsize, small);
            line-height: 17px;
            padding: 0;

            &.colored {
                background: #E3F3FA;
                width: 116px;

                @include mq.mq($until: 1100px){
                    width: 90px;
                }
            }

            &:not(.colored) {
                background: rgba(222, 229, 232, 0.1);
                width: 20px;

                @include mq.mq($until: 850px){
                    min-width: 5px;
                }
            }

            &:first-child {
                padding: 16px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                background: rgba(222, 229, 232, 0.1);
                padding-left: 30px;
                text-align: left;
                width: 260px;

                @include mq.mq($until: 1100px){
                    padding-left: 16px;
                    width: 210px;
                }

                @include mq.mq($until: 850px){
                    position: sticky;
                    z-index: 3;
                    width: 150px;
                    left: 0;
                    min-height: 34px;
                    background: rgba(252, 252, 252, 1);
                }
            }

        }

        &.order {
            td {
                background: map-get($colors, white);
                border-top: 10px solid map-get($colors, white) !important;

                @include mq.mq($until: 1100px){
                    border-top: 5px solid map-get($colors, white) !important;
                }
                
                .btn_default {
                    width: 100%;
                }
            }
        }
    }

    .table-dedicated {
        th {
          &.colored {
            font-size: 10px;
            text-align: left;
            line-height: 140%;
            text-transform: uppercase;
        
            span {
                padding: 10px;
            }
          }  
        }

        tr:nth-of-type(2) {
            td {
                border-top: 5px solid white;
            }
        }

        tr {
            &.main-info {
                td {
                    &.colored {
                        position: relative;
                        span {
                            position: absolute;
                            top: -24px;
                            width: 100%;
                            display: block;
                            border-radius: 4px;
                            background: #78CBEE;
                            color: map-get($colors, white);
                            font-size: 10px;
                            font-family: 'PressStart2P-Regular';
                            text-align: left;
                            padding: 11px 10px;
                            min-height: 44px;
                            text-transform: uppercase;


                            @include mq.mq($until: 1100px){
                                padding: 11px 0 11px 8px;
                            }

                            @include mq.mq($until: 850px){
                                position: static;
                                min-height: 52px;
                            }
                        }
                    }
                }
            }
        }
    }
}